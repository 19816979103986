<template>
    <v-app id="login" class="login-background">
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-card style="width: 15%;">
                    <v-card dark color="primary">
                        <v-card-text>
                            <v-img
                                src="https://utaw.web.app/assets/images/logos/uta-logo.svg"
                                height="60"
                                contain
                            />
                        </v-card-text>
                    </v-card>

                    <v-card-text class="pb-3">
                        <v-layout column align-center justify-center>
                            <div class="text-xs-center body-1">
                                To access your account please enter your pin
                                number
                            </div>
                            <v-form>
                                <v-text-field
                                    class="width-120 mt-3 text-xs-center"
                                    hide-details
                                    autocomplete
                                    name="pin"
                                    label="Pin Number"
                                    id="pin"
                                    type="password"
                                    v-model="pin"
                                ></v-text-field>
                            </v-form>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-layout column>
                            <v-btn
                                class="mb-3"
                                dark
                                :loading="loggingIn"
                                color="primary"
                                v-on:click="login()"
                                >Login</v-btn
                            >
                        </v-layout>
                    </v-card-actions>

                    <v-card-text v-if="invalidLogin" class="pt-0">
                        <p class="text-danger">
                            {{ loginError }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data: () => ({
        username: "",
        pin: "",
        name: "",
        invalidLogin: false,
        loggingIn: false,
        loginError: ""
    }),
    async created() {
        window.addEventListener("keyup", this.onKeyPressed);

        await this.$store.dispatch("AUTH_LOGOUT");
    },
    destroyed() {
        window.removeEventListener("keyup", this.onKeyPressed);
    },
    methods: {
        onKeyPressed(e) {
            if (e.keyCode == 13) {
                this.login();
            }
        },
        async login() {
            this.loggingIn = true;

            //this.$Progress.start();

            try {
                // if (this.username != "admin" || this.pin != "140a0707")
                //     throw new Error("Invalid Login.");

                await this.$store.dispatch("AUTH_REQUEST", {
                    username: this.username,
                    pin: this.pin
                });

                this.$router.push({
                    name: "Dashboard"
                });

                //this.$Progress.finish();
            } catch (error) {
                this.loggingIn = false;

                //this.$Progress.finish();

                this.invalidLogin = true;
                this.loginError = error;
            }
        }
    }
};
</script>
<style>
.width-120 {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;
}
</style>
